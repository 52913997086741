import React from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";


const CoursesBody = () => {

  return (
    <>
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="how-to-apply">
            <h2>Courses</h2>
            <p>
              The Journal of Economic Studies (JES) and the Nigerian Journal of Energy and Environment Economics are open access, peer-reviewed and refereed university journals published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria
            </p>
            <p>
              The Journal of Economic Studies (JES) is an open access, peer-reviewed and refereed university journal published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria. The journal has its main objectives as the provision of intellectual platform for dissemination of well researched knowledge about the society in general as well as the sharing of cutting edge information on burning economic, energy and environmental issues of national and international coverage.
            </p>
          </div>
        </div>

      </PagesBodyLayout>

    </>

  );
}

export default CoursesBody;