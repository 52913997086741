import React, { useEffect, useState } from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";
import { news } from "../../lib";
import { useParams } from "react-router-dom";
import { NewsResponseType } from "../../@types/types";

const defaultNewsPost: NewsResponseType = {
  id: 0,
  user: "",
  title: "",
  image: "",
  details: "",
  href: ""
};

const SingleEventBody = () => {
  const { newsId } = useParams<{ newsId: string }>();
  
  const [responseData, setResponseData]: [NewsResponseType, (post: NewsResponseType) => void] = useState(defaultNewsPost);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (newsId) {
      const newsItem = news.find((news) => news.id === parseInt(newsId));
      if (newsItem) {
        setResponseData(newsItem);
        setFetchError(null);
      } else {
        setFetchError("News item not found");
      }
    } else {
      setFetchError("Invalid news ID");
    }
    setIsLoading(false);
  }, [newsId]);
  
  return (
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="events-details-left-content pr-20">
            {isLoading ? (
                <h2 style={{ color: 'green', paddingTop: 50 }}>🌀 Loading data ...</h2>
            ) : fetchError ? (
                <div className="text-center mb-10 pt-10">
                  <p style={{ color: "red" }}>{fetchError}</p>
                </div>
            ) : (
                <>
                  <div className="events-image">
                    <img src={responseData.image} alt="Image" />
                  </div>
                  <div className="meetings">
                    <h2>{responseData.title}</h2>
                    <p>{responseData.details}</p>
                    <p>{responseData.details}</p>
                  </div>
                  <div className="brought">
                    <h3>Which Must Be Brought</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tem incid idunt ut labore et dolore magna aliqua. Ut enim ad minim ven iam quis nostrud xerci tation ulla mco laboris nisi ut Lorem ipsum dolor sit amet</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tem incid idunt ut labore et dolore magna aliqua. Ut enim ad minim ven</p>
                    <a href="#" className="default-btn btn">Apply Now<i className="flaticon-next"></i></a>
                  </div>
                </>
            )}
          </div>
        </div>
      </PagesBodyLayout>
  );
}

export default SingleEventBody;
