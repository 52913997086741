import React, {useRef, useState} from 'react';

import axios from "axios";
import Swal from "sweetalert2";

const ContactForm = () => {

  const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    isSubmitting: false,
    errorMessage: null
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (event: { target: { name: string; value: string; }; }) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');

  const myRef = useRef(null);

  const handleSubmit = async (e: { preventDefault: () => void; currentTarget: any; }) => {
    e.preventDefault();

    // const element2 = myRef.current;
    // console.log(element2);
    // const event_button = e.currentTarget;

    // event_button.setAttribute("data-kt-indicator", "on");
    // event_button.disabled = true;

    // type FormData = {
    //   name: string,
    //   email: string,
    //   phone: number,
    //   message: string
    // }

    // let formData: FormData = {name, email, phone: parseInt(phone), message};

    type FormData = {
      name: string,
      email: string,
      phone: string,
      message: string
    }
    let formData: FormData = data;
    // console.log(formData)

    try{

      // const loginResponse = await axios.post(`https://jsonplaceholder.typicode.com/todos/1`, formData, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     withCredentials: true
      //   },
      // })

      setData({
        ...data,
        isSubmitting: true,
        errorMessage: null
      });



      // console.log(data)

      const loginResponse = await axios.get(`https://jsonplaceholder.typicode.com/users`);

      await Swal.fire({
        title: 'Success!',
        html: 'Message sent successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 3000
      })

      // await setData({
      //   ...initialState
      // });

      // console.log(loginResponse);
      // console.log(formData);

      // event_button.removeAttribute( "data-kt-indicator");
      // event_button.disabled = false;
    }
    catch (err: any) {

      // event_button.removeAttribute( "data-kt-indicator");
      // event_button.disabled = false;
      // const errorMessage = err.message

      await setData({
        ...data,
        errorMessage: err.message
      });

      console.log(e);
    } finally {
      await setData({
        ...data,
        isSubmitting: false
      });
    }
  }

  return (
    <>
      <div className="col-lg-6">
        <div className="contacts-form">
          <h3>Leave a message</h3>
          <form id="contactForm">
            <div className="row">
              <div className="col-lg-10 col-sm-10">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    required={true}
                    data-error="Please enter your name"
                    value={data.name}
                    onChange={handleInputChange}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    required={true}
                    data-error="Please enter your email"
                    value={data.email}
                    onChange={handleInputChange}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    required={true}
                    data-error="Please enter your number"
                    className="form-control form-control-sm "
                    value={data.phone}
                    onChange={handleInputChange}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    cols={30}
                    rows={4}
                    required={true}
                    data-error="Write your message"
                    value={data.message}
                    onChange={handleInputChange}
                  ></textarea>
                  <div className="help-block with-errors" />
                </div>
              </div>
              {/*<div className="form-group">*/}
              {/*  <div className="form-check">*/}
              {/*    <input*/}
              {/*      name="gridCheck"*/}
              {/*      defaultValue="I agree to the terms and privacy policy."*/}
              {/*      className="form-check-input"*/}
              {/*      type="checkbox"*/}
              {/*      id="gridCheck"*/}
              {/*      required={true}*/}
              {/*    />*/}
              {/*    <label className="form-check-label" htmlFor="gridCheck">*/}
              {/*      I agree to the <a href="terms-conditions.html">terms</a> and{" "}*/}
              {/*      <a href="privacy-policy.html">privacy policy</a>*/}
              {/*    </label>*/}
              {/*    <div className="help-block with-errors gridCheck-error" />*/}
              {/*  </div>*/}
              {/*</div>*/}

              {data.errorMessage && (
                <span className="form-error">{data.errorMessage}</span>
              )}
              <div className="col-lg-12 col-md-12">
                <button
                  type="submit"
                  className="default-btn"
                  ref={myRef}
                  onClick={handleSubmit}
                  disabled={data.isSubmitting}
                >
                  {data.isSubmitting ? (
                    <span>Message sending ....</span>
                  ) : (
                    <span>Send Message</span>
                  )}
                </button>
                <div id="msgSubmit" className="h3 text-center hidden" />
                <div className="clearfix" />
              </div>
            </div>
          </form>
        </div>
      </div>

    </>
  );
}

export default ContactForm;