import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import LoginBody from "./LoginBody";

const Login = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`About Us`} />
        <LoginBody />
      </Layout>
    </>
  );
}

export default Login;
