import React, {useEffect, useState} from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";
// import news_data from "../_components/news_data.json"
import { news } from "../../lib"
import newsImage02 from "../../assets/images/news/news-2.jpg";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {NewsResponseType} from "../../@types/types";


const defaultNewsPosts:NewsResponseType[] = [];


const EventBody = () => {
  // const { pathname } = useLocation();

  const APP_URL = 'https://portal.nau.local/api/v1/news';

  const [responseData, setResponseData] : [NewsResponseType[], (posts: NewsResponseType[]) => void] = useState(defaultNewsPosts);
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    let didCancel = false;

        // axios.get<NewsResponseType[]>(`${APP_URL}`)
        //   .then(({data}) => {
        //     setResponseData(data);
        //     setFetchError(null);
        //   })
        //   .catch(err => {
        //     console.log(err.response.message)
        //     setFetchError(err.response.message)
        //   })
        //   .finally(()=>{
        //     setIsLoading(false);
        //   });
    
    setResponseData(news);
    setIsLoading(false);


    // Cleanup function
    return () => {
      didCancel = true;
    };


  }, []);

  return (
    <>
      <PagesBodyLayout>


        <div className="col-lg-8">
          <div
            className="latest-news-left-content pr-20"
          >
            <h2>News & Events</h2>
            <div className="latest-news-card-area">
              <div className="row">

                {isLoading &&

                  <h2 style={{color: 'green', paddingTop: 50}}>🌀 Loading data ...</h2>
                }

                {fetchError &&

                  <tr>
                    <td colSpan={8}>
                      <div className="text-center mb-10 pt-10">
                        <p style={{color: "red"}}>{`${fetchError}`}</p>
                      </div>
                    </td>

                  </tr>
                }

                {
                  !fetchError &&
                  responseData.map(news => {

                    return news !== undefined ?

                      (
                        <div className="col-lg-6 col-md-6">
                          <div className="single-news-card">
                            <div className="news-img">
                              <a href={`/events/${news.id}`}>
                                <img src={newsImage02} alt="Image" />
                              </a>
                            </div>
                            <div className="news-content">
                              <div className="list">
                                <ul>
                                  <li  key={news.id}>
                                    <i className="flaticon-user" />
                                    By <span>{news.user}</span>
                                  </li>
                                </ul>
                              </div>
                              <a href={`/events/${news.id}`}>
                                <h3>{news.title}</h3>
                              </a>
                              <Link to={`/events/${news.id}`} className="read-more-btn">
                                Read More
                                <i className="flaticon-next" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      )
                      :
                      <h2 style={{color: 'green', paddingTop: 50}}>🌀 No data Found.</h2>
                  })
                }

              </div>
            </div>
          </div>
        </div>

      </PagesBodyLayout>
    </>

  );
}

export default EventBody;
