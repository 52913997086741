import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import LecturersBody from "./LecturersBody";

const Lecturers = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Staff`} />
        <LecturersBody />
      </Layout>
    </>
  );
}

export default Lecturers;
