import titleBackground from "../../assets/images/page-banner/page-bnner-2.jpg";

type Props = {
  title1: string,
  // title2: string
  // backgroundImage: any
}

const PageTitle = (props: Props) => {
  return (
    <>
      <div className="page-banner-area" style={{backgroundImage: `url(${titleBackground})`, paddingTop: '70px',
        paddingBottom: '70px'}}>
        <div className="container">
          <div className="page-banner-content">
            <h1>{props.title1}</h1>
            {/*<ul>*/}
            {/*  <li>*/}
            {/*    <a href="index.html">Home</a>*/}
            {/*  </li>*/}
            {/*  <li>Pages</li>*/}
            {/*  <li>Graduate Admission</li>*/}
            {/*</ul>*/}
          </div>
        </div>
      </div>

    </>
  );
}

export default PageTitle;