import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import CoursesBody from "./CoursesBody";

const Courses = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Courses`} />
        <CoursesBody />
      </Layout>
    </>
  );
}

export default Courses;