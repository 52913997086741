import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
// import $ from "jquery/dist/jquery.slim"
//
// import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

// import "./assets/css/bootstrap.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/flaticon.css";
import "./assets/css/remixicon.css";
import "./assets/css/odometer.min.css";
import "./assets/css/aos.css";
import "./assets/css/style.css";
import "./assets/css/dark.css";
import "./assets/css/responsive.css";
import "./assets/images/favicon.png";

// import AOS from "aos";
import 'aos/dist/aos.css';

import Home from "./app/Home/Home";
// import CallFunctions from "./app/_components/CallFunctions";
import Admissions from "./app/Admissions/Admissions";
import Contact from "./app/Contact/Contact";
import Events from "./app/Events/Events";
import Management from "./app/Management/Management";
import Alumni from "./app/Alumni/Alumni";
import About from "./app/AboutUs/About";
import PageNotFound from "./app/404/PageNotFound";
import Courses from "./app/Courses/Courses";
import SingleEvent from "./app/Events/SingleEvent";
import Dashboard from "./app/Admin/Dashboard";
import Login from "./app/Login/Login";
import Lecturers from "./app/Lecturers/Lecturers";
import SingleLecturer from "./app/Lecturers/SingleLecturer";


function App() {

  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // CallFunctions()

  useEffect(() => {

    window.scrollTo(0, 0)

    // AOS.init();

  }, [pathname]);


  return (
    <>
      <Routes>
        <Route path='/' element={<Home/>} />
        
        <Route path='/login' element={<Login />} />

        <Route path="/about">
          <Route index={true} element={<About />} />
          <Route index={false} path="/about/management" element={<Management />} />
        </Route>
        
        <Route path="/lecturers">
          <Route index={true} element={<Lecturers />} />
          <Route index={false} path=":lecturerId" element={<SingleLecturer />} />
        </Route>


        <Route path='/admissions' element={<Admissions />} />
        <Route path='/courses' element={<Courses />} />

        <Route path="/events">
          <Route index={true} element={<Events />} />
          <Route index={false} path=":newsId" element={<SingleEvent />} />
        </Route>

        <Route path='/alumni' element={<Alumni />} />
        <Route path='/contact' element={<Contact />} />
        
        <Route path="/api">
          <Route index={true} element={<Dashboard />} />
          {/*<Route index={false} path="/admin" element={<Dashboard />} />*/}
        </Route>
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
