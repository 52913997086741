import sideBarImage from "../../assets/images/health-care/health-care-1.jpg";
import news_data from "./news_data.json";
import newsImage02 from "../../assets/images/news/news-2.jpg";
import {Link, NavLink} from "react-router-dom";

const PageSideBar = () => {
  let count = 0;

  return (
    <>
      <div className="col-lg-4">
        <div className="tranding">
          <h3>Latest News</h3>

          {
            news_data.map((news : {id: number, user: string, title: string, image: string, href: string, details: string}) => {

              if (count < 4) {
                count++;

                return (

                  <div className="tranding-box">
                    <div className="tranding-content">
                      <Link key={news.id} to={`/events/${news.id}`}>
                        <img src={sideBarImage} alt="Image" />
                      </Link>
                      <h4>
                        <NavLink to={`/events/${news.id}`}>{news.title}</NavLink>
                      </h4>
                    </div>
                  </div>
                )
              }
            })
          }

        </div>
      </div>



    </>
  );
}

export default PageSideBar;