import React from 'react'
import Navigation from "./Navigation";
import Footer from "./Footer";
import ScrollToTop from "../_components/ScrollToTop";
// import TopBar from "./TopBar";


type Props = {
  children: React.ReactNode,
}

const Layout = (props: Props) => {

    return (
       <>
         {/*<TopBar />*/}

         <Navigation/>
           <main>
             {props.children}
           </main>
         <Footer/>

         <ScrollToTop/>
       </>
    );
}

export default Layout;