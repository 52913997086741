import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import ManagementBody from "./ManagementBody";

const Management = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Management`} />
        <ManagementBody />
      </Layout>
    </>
  );
}

export default Management;