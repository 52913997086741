import {useEffect} from "react";

const CallFunc = () => {
  useEffect(() => {
    require('../../assets/js/custom.js');
    // allFunc($);
  });
}

export default CallFunc;
