import React, {useEffect} from 'react';
import {Link, NavLink} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import deptLogo from "../../assets/images/dept-unizik-logo.png";
import deptDarkLogo from "../../assets/images/dept-unizik-logo-dark.png";
import whiteLogo from "../../assets/images//white-logo.png";
// import TopSideBarModal from "./TopSideBarModal";

const Navigation = () => {

    return (
       <>
         <div className="navbar-area nav-bg-1">
           <div className="mobile-responsive-nav">
             <div className="container">
               <div className="mobile-responsive-menu">
                 <div className="logo">
                   <NavLink to={`/`} >
                     <img src={deptDarkLogo} className="main-logo img-fluid" style={{maxWidth: '70%'}} alt="logo" />
                     <img
                       src={deptDarkLogo}
                       className="white-logo"
                       alt="logo"
                     />
                   </NavLink>
                 </div>
               </div>
             </div>
           </div>
           <div className="desktop-nav">
             <div className="container">
               <nav className="navbar navbar-expand-md navbar-light">
                 <NavLink className="navbar-brand col-4" to={`/`} >
                   <img src={deptLogo}  style={{maxWidth: '40%'}} alt="logo" />
                 </NavLink>
                 <div
                   className="collapse navbar-collapse mean-menu"
                   id="navbarSupportedContent"
                 >
                   <ul className="navbar-nav ms-auto">
                     <li className="nav-item">
                       <NavLink to={ `/` } className="nav-link">Home</NavLink>
                     </li>
                     <li className="nav-item">
                       <NavLink to={ `/about` } className="nav-link dropdown-toggle">About</NavLink>
                       {/*<a href="#" className="nav-link dropdown-toggle">*/ }
                       {/*  About*/ }
                       {/*</a>*/ }
                       <ul className="dropdown-menu">
                         <li className="nav-item">
                           <NavLink to={ `/about` } className="nav-link">Department of Economics</NavLink>
                         </li>
                         <li className="nav-item">
                           <NavLink to={ `/about/management` } className="nav-link">Management</NavLink>
                         </li>
                         {/*<li className="nav-item">*/}
                         {/*  <NavLink to={ `/about/lecturers` } className="nav-link">Lecturers</NavLink>*/}
                         {/*</li>*/}
                         {/*<li className="nav-item">*/ }
                         {/*  <NavLink to={`/admissions`} className="nav-link">Admissions</NavLink>*/ }
                         {/*</li>*/ }
                       </ul>
                     </li>
                     
                     <li className="nav-item">
                       <NavLink to={ `/lecturers` } className="nav-link">Staff</NavLink>
                     </li>
                     
                     <li className="nav-item">
                       <NavLink to={ `/admissions` } className="nav-link">Admissions</NavLink>
                     </li>
                     {/*<li className="nav-item">*/ }
                     {/*  <NavLink to={`/courses`} className="nav-link">Courses</NavLink>*/ }
                     {/*</li>*/ }
                     <li className="nav-item">
                       <NavLink to={ `/events` } className="nav-link">News</NavLink>
                     </li>
                     {/*<li className="nav-item">*/ }
                     {/*  <NavLink to={`/alumni`} className="nav-link">Alumni</NavLink>*/ }
                     {/*</li>*/ }
                     {/*<li className="nav-item">*/ }
                     {/*  <Link to={`https://nauecojournals.com/index.php/administration/login`} className="nav-link">Journal</Link>*/ }
                     {/*</li>*/ }
                     
                     <li className="nav-item">
                       <NavLink to={ `/contact` } className="nav-link">Contact</NavLink>
                     </li>
                   </ul>
                   {/*ICON FOR SIDE BAR*/ }
                   <div className="others-options">
                     <Link to={ `https://nauecojournals.com/index.php/administration/login`} className="default-btn btn btn-sm">Journal <i
                         className="flaticon-next"></i></Link>
                     {/*<div className="icon">*/ }
                     {/*  <i*/ }
                     {/*    className="ri-menu-3-fill"*/ }
                     {/*    data-bs-toggle="modal"*/ }
                     {/*    data-bs-target="#sidebarModal"*/ }
                     {/*  />*/ }
                     {/*</div>*/ }
                   </div>
                 </div>
               </nav>
             </div>
           </div>
           
           {/*<TopSideBarModal />*/}

         </div>


       </>
    );
}

export default Navigation;
