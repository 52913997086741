import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import AboutUsBody from "./AboutUsBody";

const About = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`About Us`} />
        <AboutUsBody />
      </Layout>
    </>
  );
}

export default About;