import React from 'react';
import postImage04 from "../../assets/images/podcasts/podcasts-4.jpg";
import postImage05 from "../../assets/images/podcasts/podcasts-5.jpg";
import postImage06 from "../../assets/images/podcasts/podcasts-6.jpg";
import postImage07 from "../../assets/images/podcasts/podcasts-7.jpg";
import news_data from "../_components/news_data.json";
import newsImage02 from "../../assets/images/news/news-2.jpg";
import {Link} from "react-router-dom";

const HomePosts = () => {
  let delay = 0;
  let count = 0;

    return (
       <>
         <div className="podcasts-area pt-100 pb-70 bg-f4f6f9">
           <div className="container">
             <div className="row">
               <div className="col-lg-7">
                 <div className="section-title style2">
                   <h2>News & Events</h2>
                   {/*<p>*/}
                   {/*  Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit*/}
                   {/*  tellus luctus nec ullamcorper mattis{" "}*/}
                   {/*</p>*/}
                 </div>
                 <div className="row">

                   {
                     news_data.map(news => {

                       if (news !== undefined && count < 4) {
                         delay = delay + 200;
                         count++;

                         return (

                           <div
                             key={news.id}
                             className="col-lg-6 col-md-6"
                             data-aos="fade-up"
                             data-aos-duration={1200}
                             data-aos-delay={delay}
                             data-aos-once="true"
                           >
                             <div className="single-podcasts-card style3">
                               <div className="podcasts-image">
                                 <img src={postImage04} alt="Image" />
                                 {/*<a*/}
                                 {/*  className="popup-youtube play-btn"*/}
                                 {/*  href="https://www.youtube.com/watch?v=6WQCJx_vEX4"*/}
                                 {/*>*/}
                                 {/*<i className="ri-play-fill" />*/}
                                 {/*</a>*/}
                                 {/*<div className="episodes">*/}
                                 {/*  <p>Episodes: 01</p>*/}
                                 {/*</div>*/}
                               </div>
                               <div className="podcast-content">
                                 <h3>{news.title}</h3>
                                 {/*<div className="date">*/}
                                 {/*  <p>April 10,2022</p>*/}
                                 {/*</div>*/}
                               </div>
                             </div>
                           </div>
                         )
                       }
                     })
                   }

                 </div>
               </div>
               <div className="col-lg-5">
                 <div className="accordion" id="accordionExample">
                   <h3>UNIZIK's Anthem</h3>
                   <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                       <button
                         className="accordion-button"
                         type="button"
                         data-bs-toggle="collapse"
                         data-bs-target="#collapseOne"
                         aria-expanded="true"
                         // aria-controls="collapseOne"
                       >
                         Verse 1:
                       </button>
                     </h2>
                     <div>
                       <div className="accordion-body">
                         Hail! UNIZIK!
                         Pride of the State
                         and our Nation
                         Fountain of knowledge
                         and of Wisdom
                         Source of our Hope
                         and Confidence
                         Where Freedom through
                         Education we gain
                         With reverence,
                         we honour you

                       </div>
                     </div>
                     <h2 className="accordion-header" id="headingOne">
                       <button
                         className="accordion-button"
                         type="button"
                         data-bs-toggle="collapse"
                         data-bs-target="#collapseOne"
                         aria-expanded="true"
                         // aria-controls="collapseOne"
                       >
                         Refrain:
                       </button>
                     </h2>
                     <div>
                       <div className="accordion-body">
                         Our UNIZIK! Our UNIZIK
                         Here we learn
                         Discipline,
                         Self-Reliance
                         and Excellence
                         Great UNIZIK
                         We do hail you
                         We do hail you.

                       </div>
                     </div>
                     <h2 className="accordion-header" id="headingOne">
                       <button
                         className="accordion-button"
                         type="button"
                         data-bs-toggle="collapse"
                         data-bs-target="#collapseOne"
                         aria-expanded="true"
                         // aria-controls="collapseOne"
                       >
                         Verse 2
                       </button>
                     </h2>
                     <div>
                       <div className="accordion-body">
                         Hail! UNIZIK
                         Seat of Science and
                         Centre for the Arts
                         Where knowledge in
                         abundance flows
                         Your name and virtue,
                         with pride we uphold
                         For through this varsity,
                         our intellect blossoms
                         With reverence,
                         we honour you.

                       </div>
                     </div>
                   </div>

                 </div>
                 <br />
                 <div className="subscribe-area">
                   <div className="top-content">
                     <i className="flaticon-email" />
                     <h3>Subscribe To Newsletter</h3>
                     <p>Get updates to news &amp; events</p>
                   </div>
                   <form className="newsletter-form" data-toggle="validator">
                     <input
                       type="email"
                       className="form-control"
                       placeholder="Your Email"
                       name="EMAIL"
                       required={true}
                       autoComplete="off"
                     />
                     <button className="default-btn" type="submit">
                       Subscribe
                     </button>
                     <div id="validator-newsletter" className="form-result" />
                   </form>
                 </div>
               </div>
             </div>
           </div>
         </div>

       </>
    );
}

export default HomePosts;