import React from "react";
import {Link} from "react-router-dom";

const ContactForm = () => {
  return (
    <>
      <div className="col-lg-6">
        <div className="contact-and-address">
          <h2>Let's Connect</h2>
          {/*<p>*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tem*/}
          {/*  incididunt ut labore et dolore magna aliquat enim minim veniam quis nostr*/}
          {/*  exercitation labore et dolore magna aliquat{" "}*/}
          {/*</p>*/}
          <div className="contact-and-address-content">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="contact-card">
                  <div className="icon">
                    <i className="ri-phone-line" />
                  </div>
                  <h4>Contact</h4>
                  <p>
                    Mobile: <Link to="tel:+2348062750844">+2348062750844</Link>
                  </p>
                  <p>
                    Mail:{" "}
                    <Link to="mailto:economics@dept.unizik.edu.ng">
                <span
                  className="__cf_email__"
                  data-cfemail="7506141b003510110018141c195b161a18"
                >
                  economics@dept.unizik.edu.ng
                </span>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-card">
                  <div className="icon">
                    <i className="ri-map-pin-line" />
                  </div>
                  <h4>Address</h4>
                  <p>Nnamdi Azikiwe University</p>
                  <p>PMB 5025, Awka, Anambra State</p>
                </div>
              </div>
            </div>
          </div>
          <div className="social-media">
            <h3>Social Media</h3>
            {/*<p>*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod*/}
            {/*  tem incididunt ut labore et dolore magna aliquat enim*/}
            {/*</p>*/}
            <ul>
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="flaticon-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/" target="_blank">
                  <i className="flaticon-twitter" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/?lang=en" target="_blank">
                  <i className="flaticon-instagram" />
                </a>
              </li>
              <li>
                <a href="https://linkedin.com/?lang=en" target="_blank">
                  <i className="flaticon-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  );
}

export default ContactForm;