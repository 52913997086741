const ScrollToTop = () => {
  return (
    <>
      <div className="go-top">
        <i className="ri-arrow-up-s-line" />
        <i className="ri-arrow-up-s-line" />
      </div>
    </>
  );
}

export default ScrollToTop;