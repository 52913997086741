import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import ContactBody from "./ContactBody";

const Contact = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Contact Us`} />
        <ContactBody />
      </Layout>
    </>
  );
}

export default Contact;