import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import AlumniBody from "./AlumniBody";

const Alumni = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Alumni`} />
        <AlumniBody />
      </Layout>
    </>
  );
}

export default Alumni;