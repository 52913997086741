import React from 'react';
import aboutImage from "../../assets/images/about/01.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const HomeProgrammes = () => {
    return (
       <>
         <div className="eduction-area pb-70">
           <div className="container-fluid p-0">
             <div className="row g-0">
               <div
                 data-aos="fade-up"
                 data-aos-duration={1200}
                 data-aos-delay={400}
                 data-aos-once="true"
                 className="col-lg-4 col-md-6 aos-init aos-animate"
               >
                 <div className="single-education-card bg-2">
                   <div className="edication-content">
                     <div className="icon">
                       <i className="flaticon-student-with-graduation-cap" />
                     </div>
                     <a href="/">
                       <h3>UnderGraduate Programme</h3>
                     </a>
                     <a
                       href="/"
                       className="read-more-btn white-color"
                     >
                       UnderGraduate Programme
                       <i className="flaticon-next" />
                     </a>
                   </div>
                 </div>
               </div>
               <div
                 data-aos="fade-up"
                 data-aos-duration={1200}
                 data-aos-delay={600}
                 data-aos-once="true"
                 className="col-lg-4 col-md-6 aos-init aos-animate"
               >
                 <div className="single-education-card bg-3">
                   <div className="edication-content">
                     <div className="icon">
                       <i className="flaticon-college-graduation" />
                     </div>
                     <a href="/">
                       <h3>PostGraduate Programme</h3>
                     </a>
                     <a
                       href="/"
                       className="read-more-btn white-color"
                     >
                       PostGraduate Programme
                       <i className="flaticon-next" />
                     </a>
                   </div>
                 </div>
               </div>
               <div
                 data-aos="fade-up"
                 data-aos-duration={1200}
                 data-aos-delay={800}
                 data-aos-once="true"
                 className="col-lg-4 col-md-6 aos-init aos-animate"
               >
                 <div className="single-education-card bg-4">
                   <div className="edication-content">
                     <div className="icon">
                       <i className="flaticon-athletics" />
                     </div>
                     <a href="/">
                       <h3>Continuing Education Programme</h3>
                     </a>
                     <a
                       href="/"
                       className="read-more-btn white-color"
                     >
                       Continuing Education Programme
                       <i className="flaticon-next" />
                     </a>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>


       </>
    );
}

export default HomeProgrammes;