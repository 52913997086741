import React from 'react';
import Layout from "../Layouts/Layout";
import BannerArea from "./BannerArea";
import HomeProgrammes from "./HomeProgrammes";
import HomeDepartmentInfo from "./HomeDepartmentInfo";
import HomePosts from "./HomePosts";
import HomeDepartmentBannerSection from "./HomeDepartmentBannerSection";
// import HomeDepartmentVideos from "./HomeDepartmentVideos";
import HomeLatestNews from "./HomeLatestNews";

const Home = () => {

    return (
       <>
         <Layout>
           <BannerArea />
           <HomeProgrammes />
           <HomeDepartmentInfo />
           <HomeDepartmentBannerSection />
           <HomePosts />
           {/*<HomeDepartmentVideos />*/}
           {/*<HomeLatestNews />*/}

           {/*<PageContent>*/}
           {/*  <HomePortfolio/>*/}
           {/*  <HomeFeatures/>*/}
           {/*  <HomeForm/>*/}
           {/*</PageContent>*/}

         </Layout>
       </>
    );
}

export default Home;
