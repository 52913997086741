import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import SingleLecturerBody from "./SingleLecturerBody";

const SingleLecturer = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Staff`} />
        <SingleLecturerBody />
      </Layout>
    </>
  );
}

export default SingleLecturer;
