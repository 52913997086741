import React, {createContext} from 'react';
import CallFunctions from '../app/_components/CallFunctions';

export const AppContext = createContext();

export function AppProvider({ children }) {
    const contextValues = {};

    return (
        <AppContext.Provider value={contextValues}>
            <CallFunctions />
            {children}
        </AppContext.Provider>
    );
}
