import React, {useEffect, useState} from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";
// import news_data from "../_components/news_data.json"
import newsImage02 from "../../assets/images/news/news-2.jpg";
import {Link} from "react-router-dom";
import axios from "axios";

const ManagementBody = () => {

  const APP_URL = 'https://portal.nau.local/api/v1/news1'

  const [responseData, setResponseData] = useState<{id:number, user:string, title:string, href:string, details:string}[]>([]);
  const [fetchError, setFetchError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const {data} = await axios.get(`${APP_URL}`)

        setResponseData(data);
        setFetchError(null);

      } catch (err: any) {
        console.log(err.message)
        setFetchError(err.message)
      } finally {
        setIsLoading(false);
      }
    })()


  }, []);

  return (
    <>
      <PagesBodyLayout>


          <div className="col-lg-8">
            <div
              className="latest-news-left-content pr-20"
            >
              <h2>News & Events</h2>
              <div className="latest-news-card-area">
                <div className="row">

                  {isLoading &&

                    <h2 style={{color: 'green', paddingTop: 50}}>🌀 Loading data ...</h2>
                  }

                  {fetchError &&

                    <tr>
                      <td colSpan={8}>
                        <div className="text-center mb-10 pt-10">
                          <p style={{color: "red"}}>{`${fetchError}`}</p>
                        </div>
                      </td>

                    </tr>
                  }

                  {
                    !fetchError &&
                    responseData.map(news => {

                      return news !== undefined ?

                        (
                          <div className="col-lg-6 col-md-6">
                            <div className="single-news-card">
                              <div className="news-img">
                                <a href="news-details.html">
                                  <img src={newsImage02} alt="Image" />
                                </a>
                              </div>
                              <div className="news-content">
                                <div className="list">
                                  <ul>
                                    <li  key={news.id}>
                                      <i className="flaticon-user" />
                                      By <a href="/">{news.user}</a>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <i className="flaticon-tag" />*/}
                                    {/*  Social Sciences*/}
                                    {/*</li>*/}
                                  </ul>
                                </div>
                                <a href="/">
                                  <h3>{news.title}</h3>
                                </a>
                                <Link to={news.href} className="read-more-btn">
                                  Read More
                                  <i className="flaticon-next" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                      :
                        <h2 style={{color: 'green', paddingTop: 50}}>🌀 No data Found.</h2>
                    })
                  }

                </div>
              </div>
            </div>
          </div>

      </PagesBodyLayout>
    </>

  );
}

export default ManagementBody;