import React, { useEffect, useState } from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";
import { lecturers } from "../../lib";
import { useParams } from "react-router-dom";
import { LecturerResponseType } from "../../@types/types";

const defaultLecturer: LecturerResponseType = {
  id: 0,
  name: "",
  title: "",
  image: "",
  details: "",
  href: "",
  qualifications: []
};

const SingleLecturerBody = () => {
  const { lecturerId } = useParams<{ lecturerId: string }>();
  
  const [responseData, setResponseData]: [LecturerResponseType, (post: LecturerResponseType) => void] = useState(defaultLecturer);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (lecturerId) {
      const lecturerItem = lecturers.find((lecturer) => lecturer.id === parseInt(lecturerId));
      if (lecturerItem) {
        setResponseData(lecturerItem);
        setFetchError(null);
      } else {
        setFetchError("Lecturer not found");
      }
    } else {
      setFetchError("Invalid lecturer ID");
    }
    setIsLoading(false);
  }, [lecturerId]);
  
  return (
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="lecturer-details-left-content pr-20">
            {isLoading ? (
                <h2 style={{ color: 'green', paddingTop: 50 }}>🌀 Loading data ...</h2>
            ) : fetchError ? (
                <div className="text-center mb-10 pt-10">
                  <p style={{ color: "red" }}>{fetchError}</p>
                </div>
            ) : (
                <>
                  <div className="lecturer-image">
                    <img src={responseData.image} alt="Image" />
                  </div>
                  <div className="lecturer-details how-to-apply">
                    <h2>{ responseData.name }</h2>
                    <h4>{ responseData.title }</h4>
                    <p>{ responseData.details }</p>
                    <div className="apply-list">
                      <h3>Qualifications</h3>
                      <ul>
                        { responseData.qualifications.map( ( qualification, index ) => (
                            <li key={ index }>
                              <i className="ri-checkbox-circle-line" />
                              <i className="ri-checkbox-circle-line"/>
                              { qualification }
                            </li>
                        ) ) }
                      </ul>
                    </div>
                  </div>
                </>
            ) }
          </div>
        </div>
      </PagesBodyLayout>
  );
}

export default SingleLecturerBody;
