import React, {useEffect, useState} from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";
import { lecturers } from "../../lib"
import {LecturerResponseType} from "../../@types/types";
import { Link } from "react-router-dom";

const defaultLecturers:LecturerResponseType[] = [];

const LecturerBody = () => {
  const [responseData, setResponseData] : [LecturerResponseType[], (posts: LecturerResponseType[]) => void] = useState(defaultLecturers);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setResponseData(lecturers);
    setIsLoading(false);
  }, []);

  return (
    <>
      <PagesBodyLayout showSideBar={false}>
        <div className="col-lg-12">
          <div className="latest-news-left-content pr-20">
            <h2>Lecturers</h2>
            <div className="latest-news-card-area">
              <div className="row">
                {isLoading &&
                  <h2 style={{color: 'green', paddingTop: 50}}>🌀 Loading data ...</h2>
                }
                {!isLoading &&
                  responseData.map(lecturer => (
                    <div className="col-lg-3 col-md-3">
                      <div className="single-news-card">
                        <div className="news-img">
                          <a href={ `/lecturers/${ lecturer.id }` }>
                            <img src={lecturer.image} alt="Image" />
                            {/*<img src={ `https://xsgames.co/randomusers/avatar.php?g=male` } alt="Image"/>*/}
                            
                          </a>
                        </div>
                        <div className="news-content">
                          <a href={ `/lecturers/${ lecturer.id }` }>
                            <h3>{ lecturer.name }</h3>
                          </a>
                          <div className="list">
                            <span>{ lecturer.title }</span>
                          </div>
                          
                          <Link to={ `/lecturers/${ lecturer.id }` } className="read-more-btn">
                            Read More
                            <i className="flaticon-next"/>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) )
                }
              </div>
            </div>
          </div>
        </div>
      </PagesBodyLayout>
    </>
  );
}

export default LecturerBody;
