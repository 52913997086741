import {lecturersData} from './lecturersData';

export const news = [
    {
        "id": 1,
        "user": "John Doe",
        "title": "Exciting News Update",
        "image": "../../assets/images/news/news-2.jpg",
        "details": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "href": "https://google.com"

    },
    {
        "id": 2,
        "user": "Jane Smith",
        "title": "Breaking: Major Event Happening",
        "image": "../../assets/images/news/news-2.jpg",
        "details": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "href": "https://google.com"
    },
    {
        "id": 3,
        "user": "Alice Johnson",
        "title": "New Discoveries in Science",
        "image": "../../assets/images/news/news-2.jpg",
        "details": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "href": "https://google.com"
    }
]


function getRandomName() {
    const firstNames = ["John", "Jane", "Alice", "Bob", "Charlie", "David", "Eve", "Frank", "Grace", "Helen"];
    const lastNames = ["Smith", "Johnson", "Brown", "Taylor", "Miller", "Wilson", "Moore", "Clark", "Jackson", "White"];

    const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];

    return `${firstName} ${lastName}`;
}

function getRandomDetails() {
    const words = ["Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit", "sed", "do", "eiusmod", "tempor", "incididunt", "ut", "labore", "et", "dolore", "magna", "aliqua"];
    let details = "";

    for(let i = 0; i < 250; i++) {
        details += words[Math.floor(Math.random() * words.length)] + " ";
        if(details.split(" ").length - 1 === 200) {
            break;
        }
    }

    return details.trim();
}

function getRandomQualifications() {
    const qualifications = [
        "PhD, Telecoms Engineering",
        "MSc, Computer Science",
        "BSc, Electrical Engineering",
        "MBA, Business Administration",
        "MA, English Literature",
        "PhD, Physics",
        "MSc, Mathematics",
        "BSc, Chemistry",
        "PhD, Biology",
        "MSc, Economics",
        "BSc, Computer Engineering"
    ];
    let randomQualifications = [];

    for(let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * qualifications.length);
        randomQualifications.push(qualifications[randomIndex]);
        qualifications.splice(randomIndex, 1); // Remove used qualification
    }

    return randomQualifications;
}

//export const lecturers = Array.from({ length: 20 }, (_, i) => ({
//    id: i + 1,
//    name: getRandomName(),
//    title: `Professor ${i + 1}`,
//    image: `https://xsgames.co/randomusers/avatar.php?g=male`,
//    details: getRandomDetails(),
//    href: "https://google.com",
//    qualifications: getRandomQualifications()
//}));

export const lecturers = lecturersData

