import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";

const PageNotFound = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`404 Error! Page Not Found`} />

      </Layout>
    </>
  );
}

export default PageNotFound;