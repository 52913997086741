import React from 'react';
import PageSideBar from "./PageSideBar";

type Props = {
  children: React.ReactNode,
  showSideBar?: boolean
}


const PagesBodyLayout = ({ children, showSideBar = true }: Props) => {

  return (
    <>
      <div className="graduate-admission pt-70 pb-70">
        <div className="container">
          <div className="row">
            {children}
            
            {
                showSideBar &&
                <PageSideBar />
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default PagesBodyLayout;
