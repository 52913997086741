export const lecturersData = [
    {
        id: 1,
        name: 'Anisiobi, Chinwe Ann',
        title: 'Lecturer II',
        image: 'Anisiobi (Custom).jpg',
        details: 'An intelligent economist with significant experience in teaching and research position with in-depth knowledge of data analysis, using econometric models to interpret current trends and recommend solutions to economic problems. Teaching and research interests include: monetary economics, international economics, energy and environmental economics.',
        email: "ca.anisiobi@unizik.edu.ng",
        href: '',
        qualifications: [
            "PhD in Economics (In view) - Nnamdi Azikiwe University Awka",
            "M.Sc. in Economics - Nnamdi Azikiwe University Awka (2019)",
            "PGDE in Education - Institute of Ecumenical Education, Thinker's Corner, Enugu (2014)",
            "B.Sc. in Economics (upper division) - Caritas University Amorji-Nike, Enugu (2011)"
        ]
    },
    {
        id: 2,
        name: 'Benjamin Yabanatu Joshua',
        title: 'Lecturer',
        image: 'Benjamin (Custom).jpg',
        details: 'Teaches Macroeconomics, Development Economics, History of Economic Thought, Monetary Economics and Principle of Economics. Research interests include Development Economics, Energy Economics and Health Economics.',
        email: "by.joshua@unizik.edu.ng",
        href: '',
        qualifications: [
            "M.Sc. Economics - Kaduna State University, Kaduna",
            "B.Sc. Economics - Kaduna State University, Kaduna"
        ]
    },
    {
        id: 3,
        name: 'Prof Ebele S. Nwokoye',
        title: 'Professor of Development and Human Resource Economics',
        image: 'Prof. Ebele (Custom).jpg',
        details: 'Professor of Development and Human Resource Economics and the Immediate Past Head, Department of Economics, Faculty of Social Sciences, Nnamdi Azikiwe University Awka Anambra State. Editor of Journal of Economic Studies. Research Fellow of the African Heritage Institute Enugu, Research Associate of the Center for Sustainable Development UNIZIK, and Full Member of the Nigerian Economic Society. Currently serves as the Inaugural Chair for the Nigerian Economic Society, Anambra State Chapter.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Development Economics - Nnamdi Azikiwe University Awka",
            "M.Sc. in Human Resource Economics - Nnamdi Azikiwe University Awka",
            "B.Sc. (Second Class Honours, Upper Division) - University of Uyo, Uyo Akwa Ibom State"
        ]
    },
    {
        id: 4,
        name: 'Callistus Tabansi Okeke',
        title: 'Lecturer',
        image: 'Callistus (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University Awka. Has published several articles in both local and international journals. Areas of interest are Development Economics, Business Economics and Quantitative Economics.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Economics (in view)",
        ]
    },
    {
        id: 5,
        name: 'Chidiogo Jane Okpala',
        title: 'Lecturer',
        image: 'Chidiogo (Custom).jpg',
        details: 'Lecturer in the Department of Economics of Nnamdi Azikiwe University, Awka, Nigeria. Has interest in Development Economics, Labour Economics and Public Finance. Has worked in various capacities as a Teacher, financial secretary, Business Manager, Program Developer and a Supervisor with Unicef Immunization team. Speaks Igbo and English languages.',
        email: "",
        href: '',
        qualifications: []
    },
    {
        id: 6,
        name: 'Chika Priscilla Imoagwu',
        title: 'Lecturer',
        image: 'Chika (Custom).jpg',
        details: `Researcher and lecturer in the Department of Economics, Faculty of Social sciences, Nnamdi Azikiwe University Awka, Nigeria. Teaching and research experiences are in the field of Development Economics, Monetary Economics, Health Economics, Public Policy as well as Energy Economics. Member of the Teachers' Registration Council of Nigeria (TRCN) and Nigerian Economic Society (NES).`,
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Economics (in view)",
            "M.Sc. in Economics",
            "PGD Economics",
            "B.Sc. Hons. (Economics/Education)"
        ]
    },
    {
        id: 7,
        name: 'Madueke, Chinwe Monica',
        title: 'Lecturer',
        image: 'Chinwe (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Faculty of Social Sciences, Nnamdi Azikiwe University, Awka, Anambra State, Nigeria. Areas of specialization and research experiences are in the field of Monetary Economics, Development Economics and International Economics. Has published in many journals both local and International.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Economics (in view) - University of Nigeria Nsukka",
            "M.Sc. in Economics - University of Nigeria Nsukka, Enugu State",
            "B.Sc. in Economics - Imo State University, Owerri",
            "HND in Accountancy - Federal Polytechnic, Oko, Anambra State"
        ]
    },
    {
        id: 8,
        name: 'Dr. Alexander Chinedu Orji',
        title: 'Lecturer',
        image: 'Dr. Alex (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University Awka. Over three years experience in teaching, classroom management and research analysis. Teaching and research interests include Principles of Economics, Microeconomics, Macroeconomics, Statistics, Monetary, Planning, Development and International Economics.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Economics"
        ]
    },
    {
        id: 9,
        name: 'Dr. Amaka Gertrude Metu',
        title: 'Associate Professor of Economics',
        image: 'Dr. Amaka (Custom).jpg',
        details: 'Currently the HoD of Economics Department and PG sub-Dean of the Faculty of Social Sciences, Nnamdi Azikiwe University Awka Nigeria. Research Fellow of the Pan-African Scientific Research Council (PASRC), Associate Fellow of both the Center for Migration Studies and Centre for Sustainable Development, Nnamdi Azikiwe University, Awka. Teaching and research interests include micro-and macro-economic policy, public sector economics, development studies and entrepreneurship studies.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Development Economics (2017)",
            "M.Sc. Economics - University of Port Harcourt",
            "BA(Ed) - Ahmadu Bello University Zaria, Nigeria (2009)"
        ]
    },
    {
        id: 10,
        name: 'Dr. Benedict Ikemefuna Uzoechina',
        title: 'Senior Lecturer',
        image: 'Dr. Benedict (Custom).jpg',
        details: 'Senior Lecturer and Researcher at the Department of Economics, Nnamdi Azikiwe University, Awka. Teaching and research interests are energy, environmental and financial economics. Currently the Managing Editor of Journal of Economic Studies and the Nigerian Journal of Energy and Environmental Economics. Consultant for various projects including water supply infrastructure development and rural roads development.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Development Economics",
            "Law degree (Member of the Nigerian Bar Association)"
        ]
    },
    {
        id: 11,
        name: 'Dr. Collins Chidubem Umeghalu',
        title: 'Lecturer',
        image: 'Dr. Collins (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University, Awka',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Economics"
        ]
    },
    {
        id: 12,
        name: 'Dr. Edwin Udochukwu Nwachukwu',
        title: 'Lecturer II',
        image: 'Dr. Edwin (Custom).jpg',
        details: 'Lecturer II academic staff at Department of Economics Nnamdi Azikiwe University, Awka. Areas of specializations/Interest include: Development Economics, Quantitative Economics (Mathematical Economics), Public Finance and International Economics.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Development Economics - Imo State University",
            "M.Sc. Economics - Nnamdi Azikiwe University, Awka",
            "B.Sc. Economics - Nnamdi Azikiwe University, Awka"
        ]
    },
    {
        id: 13,
        name: 'Dr. Joan Nwamaka Ozoh',
        title: 'Lecturer II',
        image: 'Dr. Joan (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University, Awka. Specializes in Human Resources and Development Economics. Has numerous publications and conference papers in various areas of economics.',
        email: "jn.ozoh@unizik.edu.ng",
        href: '',
        qualifications: [
            "Ph.D. Economics (In View) - Nnamdi Azikiwe University, Awka",
            "M.Sc. Economics (Human Resources) - Nnamdi Azikiwe University, Awka (2017)",
            "B.Sc. Economics - Nnamdi Azikiwe University, Awka (2006)"
        ]
    },
    {
        id: 14,
        name: 'Dr. Christopher Ulua Kalu',
        title: 'Reader and Head of Department',
        image: 'Dr. Kalu (Custom).jpg',
        details: 'Head of Department of Economics, Nnamdi Azikiwe University, Awka. Research interests focus on dynamic linkages between macroeconomic policies in developing countries and their effects on economic growth and poverty-inequality reduction. Editor of multiple journals and consultant for various development projects.',
        email: "cu.kalu@unizik.edu.ng",
        href: '',
        qualifications: [
            "Ph.D. in Development Macroeconomics - Nnamdi Azikiwe University, Awka",
            "M.Sc. in Development Economics - University of Ibadan",
            "M.Sc. in Health Economics, Management and Policy - University of Nigeria",
            "MBA - Enugu State University of Science and Technology (ESUT BUSINESS SCHOOL)"
        ]
    },
    {
        id: 15,
        name: 'Mrs. Onwuka Irene Nkechi',
        title: 'Lecturer II',
        image: 'Dr. Onwuka (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University Awka. Research interests include exchange rate volatility, government expenditure, international trade, and social inclusion. Has efficient research skills and is good at teamwork.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. Candidate - Chukwuemeka Odumegwu Ojukwu University, Igbariam campus",
            "M.Sc. in Development Economics - Nnamdi Azikiwe University Awka (2020)",
            "Postgraduate Degree in Education (PGDE) - National Open University of Nigeria (2021)",
            "Postgraduate Degree (PGD) in Development Economics - Nnamdi Azikiwe University Awka (2012)",
            "Higher National Diploma - Institute of Management and Technology (IMT) Enugu (2002)"
        ]
    },
    {
        id: 16,
        name: 'Dr. Uju V. Okoli',
        title: 'Lecturer',
        image: 'Dr. Uju (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Faculty of Social Sciences, Nnamdi Azikiwe University Awka. Associate member of the Institute of Chartered Economists of Nigeria.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D. in Development Economics - Nnamdi Azikiwe University Awka",
            "M.Sc. in Quantitative Economics - Nnamdi Azikiwe University Awka",
            "MBA in Finance",
            "Postgraduate Diploma in Education"
        ]
    },
    {
        id: 17,
        name: 'Ezenwobi Ngozi Florence',
        title: 'Lecturer',
        image: 'Ezenwobi (Custom).jpg',
        details: 'Lecturer in the Department of Economics, Nnamdi Azikiwe University Awka. Specializes in Monetary Economics.',
        email: "",
        href: '',
        qualifications: []
    },
    {
        id: 18,
        name: 'Geraldine Ejiaka Nzeribe',
        title: 'Senior Lecturer',
        image: 'Geraldine (Custom).jpg',
        details: 'Geraldine Ejiaka Nzeribe is a female Economist from Nigeria specializing in Development Economics and International Finance. Her research interests include development, health, gender, migration, and governance issues. She has published in reputable journals and attended numerous conferences. She has been mentoring students for over seven years in academics and research.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc in Economics",
            "M.Sc in Economics",
            "Ph.D in Economics",
        ]
    },
    {
        id: 19,
        name: 'Machi Ignatius Okoye',
        title: 'Senior Lecturer',
        image: 'Machi  (Custom).jpg',
        details: 'Machi Ignatius Okoye is a senior lecturer in the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria.',
        email: "",
        href: '',
        qualifications: [
            "",
        ]
    },
    {
        id: 20,
        name: 'Itodo Christian Itodo',
        title: 'Lecturer',
        image: 'Mr. Itodo (Custom).jpg',
        details: 'Mr Itodo Christian Itodo is a lecturer in the Department of Economics, Nnamdi Azikwe University, Awka, Anambra state, Nigeria. His research interests include Macroeconomics, Public finance, and Monetary Economics.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc in Economics from Caritas University, Amorji-Nike, Enugu State, Nigeria",
            "M.Sc in Economics from the University of Jos",
        ]
    },
    {
        id: 21,
        name: 'Ndubuisi Anaenugwu',
        title: 'Lecturer',
        image: 'Mr. Ndubuisi (Custom).jpg',
        details: 'Ndubuisi Anaenugwu hails from Enugwu-ukwu in Njikoka Local Government Area, Anambra State, Nigeria. He started his career in the financial market before venturing into academia. He has authored many articles on economic development of Nigeria and has been teaching economics at Nnamdi Azikiwe University, Awka since 2019, with a special interest in monetary economics.',
        email: "",
        href: '',
        qualifications: [
            "",
        ]
    },
    {
        id: 22,
        name: 'Okpala Theodore Emenike',
        title: 'Lecturer',
        image: 'Mr. Okpala (Custom).jpg',
        details: 'Okpala Theodore Emenike specializes in Monetary Economics.',
        email: "",
        href: '',
        qualifications: [
            "BSc Economics",
            "MSc in view",
        ]
    },
    {
        id: 23,
        name: 'Okaforocha Chika Maureen',
        title: 'Lecturer',
        image: 'Mrs. Okaforocha (Custom).jpg',
        details: 'Mrs Okaforocha Chika Maureen is a lecturer in the Department of Economics, Nnamdi Azikwe University Awka, Anambra State, Nigeria. Her research interests include Development Economics, Macroeconomics and International Economics.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc in Economics from Chukwuemeka Odumegwu Ojukwu University, Anambra State",
            "M.Sc in Economics from Imo State University",
            "Ph.D in Economics (in progress) from Michael Okpara University of Agriculture, Umudike, Abia State",
        ]
    },
    {
        id: 24,
        name: 'Oladipo Abimbola Oluwaseun',
        title: 'Lecturer II',
        image: 'Oladipo (Custom).jpg',
        details: 'Oladipo Abimbola Oluwaseun is a highly motivated and committed lecturer with experience in teaching, supervision, invigilation, and research. Her research includes topics such as fiscal policy, monetary policy, government expenditure, and foreign direct investment.',
        email: "oa.oladipo@unizik.edu.ng",
        href: '',
        qualifications: [
            "B.Sc. Banking and Finance (Second Class Upper Division) from University of Ado Ekiti, 2010",
            "M.Sc. Economics from Nigerian Defence Academy, Kaduna, 2019",
            "Ph.D Economics (in view) from Nigerian Defence Academy, Kaduna",
        ]
    },
    {
        id: 25,
        name: 'Onyeka Chinwe Goodness',
        title: 'Lecturer',
        image: 'Onyeka  (Custom).jpg',
        details: 'Onyeka Chinwe Goodness is a lecturer with a specialization in Monetary Economics. She aspires to be a Researcher extraordinaire in the nearest future.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc in Economics (Second Class Honours, Upper Division) from Nnamdi Azikiwe University, Awka",
            "M.Sc in Economics (in progress) from Nnamdi Azikiwe University, Awka",
        ]
    },
    {
        id: 26,
        name: 'Ebele S. Nwokoye',
        title: 'Professor',
        image: 'Prof. Ebele (Custom).jpg',
        details: 'Prof. Ebele S. Nwokoye is a Professor of Development and Human Resource Economics. She is the Immediate Past Head of the Department of Economics at Nnamdi Azikiwe University. She has authored four books and written over seventy journal articles. She aspires to develop macroeconomic models useful for the African and Nigerian economies.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc (Second Class Honours, Upper Division) from University of Uyo",
            "M.Sc in Human Resource Economics from Nnamdi Azikiwe University, Awka",
            "Ph.D in Development Economics from Nnamdi Azikiwe University, Awka",
        ]
    },
    {
        id: 27,
        name: 'Kenneth Onyebuchi Obi',
        title: 'Professor',
        image: 'Prof. Kenneth (Custom).jpg',
        details: 'Kenneth Onyebuchi Obi is a Professor of Development Economics. He has been teaching at the Department of Economics of Nnamdi Azikiwe University since 2002. His main research interests are economic development, international economics and macroeconomics, particularly economic growth, inflation and unemployment.',
        email: "",
        href: '',
        qualifications: [
            "Higher National Diploma in Statistics from Institute of Management and Technology (IMT) Enugu",
            "B.Sc from Nnamdi Azikiwe University, Awka",
            "M.Sc in International Economics from Nnamdi Azikiwe University, Awka",
            "Ph.D from Nnamdi Azikiwe University, Awka",
        ]
    },
    {
        id: 28,
        name: 'Okafor, Samuel Oseloka',
        title: 'Professor',
        image: 'Prof. Okafor (Custom).jpg',
        details: 'Professor Samuel Oseloka Okafor is a Nigerian academic in the Department of Economics at Nnamdi Azikiwe University. His research interests include Monetary Economics, Public Finance, Human Resource Development, and International Economics. He teaches courses at both undergraduate and graduate levels.',
        email: "so.okafor@unizik.edu.ng",
        href: '',
        qualifications: [
            "B.A (Hon) Economics - First Class",
            "M.A. Economics",
            "M.A. Education",
            "Diploma in Gandhian Studies",
            "PGD Gandhian Studies",
            "Certificate in Computer Science & Applications",
            "Ph.D from Panjab University",
        ]
    },
    {
        id: 29,
        name: 'Uju Regina Ezenekwe',
        title: 'Professor',
        image: 'Prof. Uju (Custom).jpg',
        details: 'Professor Uju Regina Ezenekwe is a professor of economics at Nnamdi Azikiwe University, Awka, Nigeria. Her research interests include macroeconomics policy, project evaluation, economics of production, migration and development/health economics. She has served in various academic and administrative roles and is a member of several professional associations.',
        email: "",
        href: '',
        qualifications: [
            "Ph.D in Economics",
            "MBA in Management Technology from Federal University of Technology, Owerri",
            "Certificate in Entrepreneurship in Emerging Economies from HarvardX",
        ]
    },
    {
        id: 30,
        name: 'Uzonwanne, Maria Chinecherem',
        title: 'Associate Professor',
        image: 'Rev. Sr. Dr. Chinecherem (Custom).jpg',
        details: 'Rev. Sr. Dr. Uzonwanne, Maria Chinecherem is an Associate Professor in the Department of Economics at Nnamdi Azikiwe University. Her research interests include Microeconomics, Development Economics, Problems and Policies of Development, Economics of Development and Planning, Monetary Economics, and Qualitative Research.',
        email: "",
        href: '',
        qualifications: [
            "B.Sc in Development Economics from Pontifical Gregorian University, Rome, Italy",
            "M.Sc in Development Economics from Pontifical Gregorian University, Rome, Italy",
            "Ph.D in Development Economics from Pontifical Gregorian University, Rome, Italy",
            "M.Sc in Management of Non-Governmental Organization (NGO) from Pontifical University Angelicum, Rome, Italy",
            "Diploma in Health Science from Pontifical Urban University, Rome, Italy",
            "Certificate in Entrepreneurship in Emerging Economies from Harvard University, USA",
        ]
    },
    {
        id: 31,
        name: 'Ukeje Chiemezie Desmond',
        title: 'Lecturer',
        image: 'Ukeje (Custom).jpg',
        details: 'Mr. Ukeje Chiemezie Desmond is a Lecturer in the Department of Economics at Nnamdi Azikiwe University. His research interests include development, health, climate, public sector, and policy economics. He has over four years of teaching experience and has authored several scholarly articles.',
        email: "",
        href: '',
        qualifications: [
            "",
        ]
    },
    {
        id: 32,
        name: 'Muhammed Sule',
        title: 'Lecturer II',
        image: 'Mr. Sule (Custom).jpg',
        details: 'Muhammed Sule is a Lecturer II in the Department of Economics at Nnamdi Azikiwe University (UNIZIK), Awka. His teaching interests include Quantitative Economics, and his research interests span Development, Monetary, and International Economics. He has published several papers in international journals.',
        email: "",
        href: '',
        qualifications: [
            "M.Sc. Economics from Usmanu Danfodiyo University, Sokoto (2013)",
            "B.Sc. Economics from Usmanu Danfodiyo University, Sokoto (2005)",
            "P.D.E from Federal College of Education (Technical), Gusau (2020)",
        ]
    },
];
