import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
// import ManagementBody from "./ManagementBody";

const Dashboard = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Dashboard`} />
        {/*<ManagementBody />*/}
      </Layout>
    </>
  );
}

export default Dashboard;
