import React from 'react';
import departmentInfoImage from "../../assets/images/campus-information/campus-2.jpg";
import {Link} from "react-router-dom";
// import servicesBgImage from "../../assets/images/bg/07.png";
// import {Link} from "react-router-dom";

const HomeDepartmentInfo = () => {
    return (
       <>
         <div className="campus-information-area pb-70">
           <div className="container">
             <div className="row align-items-center">
               <div className="col-lg-6">
                 <div className="campus-image">
                   <img
                     src={departmentInfoImage}
                     alt="Image"
                   />
                 </div>
               </div>
               <div className="col-lg-6">
                 <div className="campus-content style-2">
                   <div className="campus-title">
                     <h2>Department Information</h2>
                     <p>
                       The Department of Economics, Faculty of Social Sciences, Nnamdi Azikiwe University, Awka, Anambra State, Nigeria, calls for well researched academic articles for publication in the 2020 volume of its journal entitled: Journal of Economic Studies (ISSN 1119-2259).
                     </p>
                   </div>
                   <div className="list">
                     <div className="row">
                       <div className="col-lg-6 col-md-6">
                         <ul>
                           <li>
                             <i className="ri-check-fill" />
                             <p>Graduate Programs</p>
                           </li>
                           <li>
                             <i className="ri-check-fill" />
                             <p>Doctoral Degrees</p>
                           </li>
                           <li>
                             <i className="ri-check-fill" />
                             <p>Alumni &amp; Giving</p>
                           </li>
                         </ul>
                       </div>
                       <div className="col-lg-6 col-md-6">
                         <ul>
                           <li>
                             <i className="ri-check-fill" />
                             <p>Undergraduate</p>
                           </li>
                           <li>
                             <i className="ri-check-fill" />
                             <p>International Hubs</p>
                           </li>
                           <li>
                             <i className="ri-check-fill" />
                             <p>Global Students</p>
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                   <div className="counter">
                     <div className="row">
                       <div className="col-lg-4 col-4">
                         <div className="counter-card">
                           <h1>
                    <span className="odometer" data-count={65}>
                      00
                    </span>
                             <span className="target">+</span>
                           </h1>
                           <p>Years Of Experience</p>
                         </div>
                       </div>
                       <div className="col-lg-4 col-4">
                         <div className="counter-card">
                           <h1>
                    <span className="odometer" data-count={30}>
                      00
                    </span>
                             <span className="target heading-color">k</span>
                             <span className="target">+</span>
                           </h1>
                           <p>Global Students</p>
                         </div>
                       </div>
                       <div className="col-lg-4 col-4">
                         <div className="counter-card">
                           <h1>
                    <span className="odometer" data-count={52}>
                      00
                    </span>
                             <span className="target">+</span>
                           </h1>
                           <p>Student Nationalities</p>
                         </div>
                       </div>
                     </div>
                   </div>
                   <Link to="/about" className="default-btn btn">
                     Read More About Us
                     <i className="flaticon-next" />
                   </Link>
                 </div>
               </div>
             </div>
           </div>
         </div>

       </>
    );
}

export default HomeDepartmentInfo;