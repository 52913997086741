import React from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";


const AlumniBody = () => {
  return (
    <>
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="how-to-apply">
            <h2>Alumni</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius
              mod tem incid idunt ut labore et dol magna aliqua. Ut enim ad minim
              ven iam quis nostrud xerci tation Lorem ipsum dolor sit amet, conse
              ctetur adipiscing elit, sed do eius mod tem incid idunt ut labore et
              dol magna aliqua. Ut enim ad minim ven iam quis nostrud xerci tation{" "}
            </p>
          </div>
        </div>

      </PagesBodyLayout>
    </>

  );
}

export default AlumniBody;