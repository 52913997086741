import titleBackground from "../../assets/images/page-banner/page-bnner-2.jpg";
import {Link} from "react-router-dom";

const HomeDepartmentBannerSection = () => {
    return (
       <>
         <div className="admisssion-area ptb-100 admission-bg" style={{backgroundImage: titleBackground}}>
           <div className="container">
             <div className="row align-items-center">
               <div className="col-lg-6 col-md-6">
                 <div className="admission-left-content">
                   <h2>Discipline, Self-Reliance & Excellence. Join Us Today!</h2>
                   {/*<p>*/}
                   {/*  Lorem ipsum dolor sit amet consectetur adipiscing elit ut elit*/}
                   {/*  tellus luctus nec ullamcorper mattis*/}
                   {/*</p>*/}
                   <Link to="/admissions" className="default-btn btn">
                     More on admission
                     <i className="flaticon-next" />
                   </Link>
                 </div>
               </div>
               <div className="col-lg-6 col-md-6">
                 <div className="admission-right-content">
                   <ul>
                     <li>
                       <p>Watch Video</p>
                     </li>
                     <li>
                       <div className="icon">
                         <a
                           className="popup-youtube play-btn"
                           href="https://www.youtube.com/watch?v=6iotwm1DskQ"
                         >
                           <i className="ri-play-fill" />
                         </a>
                       </div>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
         </div>

       </>
    );
}

export default HomeDepartmentBannerSection;