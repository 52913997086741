import React from 'react';
import deptLogo from "../../assets/images/dept-unizik-logo.png";
import footerBgImage from "../../assets/images/shape-1.png"
import {Link} from "react-router-dom";

const Footer = () => {
    return (
       <>
         <div className="footer-area pt-70 pb-70">
           <div className="container">
             <div className="row">
               <div className="col-lg-4 col-sm-6">
                 <div className="footer-logo-area">
                   <Link to="/">
                     <img src={deptLogo} style={{maxWidth: '50%'}} alt="logo" />
                   </Link>
                   <p>
                     The Journal of Economic Studies (JES) and the Nigerian Journal of Energy and Environment Economics are open access, peer-reviewed and refereed university journals published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria
                   </p>
                   <div className="contact-list">
                     <ul>
                       <li>
                         <a href="tel:+2348062750844">+2348062750844</a>
                       </li>
                       <li>
                         <a href="mailto:economics@dept.unizik.edu.ng">
                  <span
                    className="__cf_email__"
                    data-cfemail="650401080c0b2516040b104b000110"
                  >
                    economics@dept.unizik.edu.ng
                  </span>
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-lg-3 col-sm-6">
                 <div className="footer-widjet">
                   <h3>Campus Life</h3>
                   <div className="list">
                     <ul>
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Accessibility</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Financial Aid</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Food Services</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Housing</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Information Technologies</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Student Life</a>*/}
                       {/*</li>*/}
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-lg-3 col-sm-6">
                 <div className="footer-widjet">
                   <h3>Our Campus</h3>
                   <div className="list">
                     <ul>
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Acedemic</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Planning &amp; AdminiStartion</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Campus Safety</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Office of the Chancellor</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Facility Services</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="campus-life.html">Human Resources</a>*/}
                       {/*</li>*/}
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-lg-2 col-sm-6">
                 <div className="footer-widjet">
                   <h3>Academics</h3>
                   <div className="list">
                     <ul>
                       {/*<li>*/}
                       {/*  <a href="academics.html">Canvas</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="academics.html">Catalyst</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="academics.html">Library</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="academics.html">Time Schedule</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="academics.html">Apply For Admissions</a>*/}
                       {/*</li>*/}
                       {/*<li>*/}
                       {/*  <a href="academics.html">Pay My Tuition</a>*/}
                       {/*</li>*/}
                     </ul>
                   </div>
                 </div>
               </div>
             </div>
             <div className="shape">
               <img src={footerBgImage} alt="Image" />
             </div>


             <div className="row pb-0">
               <div className="col-md-12 text-center" style={{color: 'white'}}><span>Copyright © 2023. <a href="https://www.schooware.com" style={{color: 'white'}} target={`_blank`}>Created by <u>SchooWare</u></a> for the Department of Economics, NAU, Awka</span>
               </div>
             </div>
           </div>

         </div>


       </>
    );
}

export default Footer;