import React from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";


const LoginBody = () => {

  return (
    <>
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="login-area pt-100 pb-70">
            <div className="container">
              <div className="login">
                <h3>Login</h3>
                <form>
                  <div className="form-group">
                    <input type="email" id="email" className="form-control" placeholder="Username Or Email Address*"/>
                  </div>
                  <div className="form-group">
                    <input type="password" id="password" className="form-control" placeholder="Password*"/>
                  </div>
                  <button type="submit" className="default-btn btn active">Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      
      </PagesBodyLayout>
    
    </>
  
  );
}

export default LoginBody;
