import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import EventsBody from "./EventsBody";

const Events = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`News & Events`} />
        <EventsBody />
      </Layout>
    </>
  );
}

export default Events;