import React from "react";
import PagesBodyLayout from "../_components/PagesBodyLayout";


const AboutUsBody = () => {

  return (
    <>
      <PagesBodyLayout>
        <div className="col-lg-8">
          <div className="how-to-apply">
            <h2>About Us</h2>
            <p>
              The Journal of Economic Studies (JES) and the Nigerian Journal of Energy and Environment Economics are open access, peer-reviewed and refereed university journals published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria
            </p>
            <p>
              The Journal of Economic Studies (JES) is an open access, peer-reviewed and refereed university journal published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria. The journal has its main objectives as the provision of intellectual platform for dissemination of well researched knowledge about the society in general as well as the sharing of cutting edge information on burning economic, energy and environmental issues of national and international coverage. The journal is poised to becoming the leading journals nationally and across the globe. The journal publishes well researched and technically sound papers in the field of economics and other sister disciplines such as sociology, political science, accounting etc. It is published twice every year (May and November) in both print and online versions.

              JES only publish original papers free from plagiarism and welcomes original papers from contributors throughout the year.
            </p>
          </div>
        </div>

      </PagesBodyLayout>

    </>

  );
}

export default AboutUsBody;