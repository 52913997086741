import ContactForm from "./ContactForm";
import ContactAddress from "./ContactAddress";

const ContactBody = () => {

  return (
    <>
      <div className="contact-us-area pt-100 pb-70">
        <div className="container">
          <div className="row">

            <ContactForm />
            <ContactAddress />

          </div>
        </div>
      </div>

    </>

  );
}

export default ContactBody;