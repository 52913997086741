import PageSideBar from "../_components/PageSideBar";

const AdmissionBody = () => {
  return (
    <>
      <div className="graduate-admission pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="how-to-apply">
                <h2>Detail On How To Apply</h2>
                <p>
                  The Journal of Economic Studies (JES) is an open access, peer-reviewed and refereed university journal published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria. The journal has its main objectives as the provision of intellectual platform for dissemination of well researched knowledge about the society in general as well as the sharing of cutting edge information on burning economic, energy and environmental issues of national and international coverage.
                </p>
                <div className="apply-list">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Start Online Submission
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Submit The Form
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Review The Submission
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Gather Necessary Documents
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Interviewing Process
                        </li>
                        <li>
                          <i className="ri-checkbox-circle-line" />
                          Last Decision
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="submit">
                  <h3>Submit Your Documents</h3>
                  <p>
                    The Journal of Economic Studies (JES) is an open access, peer-reviewed and refereed university journal published by the Department of Economics, Nnamdi Azikiwe University, Awka, Nigeria.
                  </p>
                  <p>
                    The journal has its main objectives as the provision of intellectual platform for dissemination of well researched knowledge about the society in general as well as the sharing of cutting edge information on burning economic, energy and environmental issues of national and international coverage.
                  </p>
                  <a href="#" className="default-btn btn">
                    Submit Your Documents
                    <i className="flaticon-next" />
                  </a>
                </div>
              </div>
            </div>

            <PageSideBar />

          </div>
        </div>
      </div>


    </>

  );
}

export default AdmissionBody;