import React from 'react';
import {Link} from "react-router-dom";
// import bg04 from "../../assets/images/bg/04.png";
// import bannerImage from "../../assets/images/banner/03.png";
// import {Link} from "react-router-dom";

const BannerArea = () => {
    return (
       <>
         <div className="banner-area">
           <div className="">
             <div className="slider-item banner-bg-7" style={{paddingTop: "100px", paddingBottom: "100px"}}>
               <div className="container-fluid">
                 <div className="slider-content style2">
                   <h1>Welcome to the Department of Economics</h1>
                   {/*<p>*/}
                   {/*  Lorem ipsum dolor sit amet consectetur adipiscing elit. Ut elit*/}
                   {/*  tellus luctus nec ullamcorper mattis pulvinar dapibus dolor sit amet*/}
                   {/*  consec*/}
                   {/*</p>*/}
                   <Link to="/admissions" className="default-btn btn">
                     Your Journey Starts Here
                     <i className="flaticon-next" />
                   </Link>
                 </div>
               </div>
             </div>
           </div>
         </div>


       </>
    );
}

export default BannerArea;