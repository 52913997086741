import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import AdmissionBody from "./AdmissionBody";

const Admissions = () => {

  return (
    <>
      <Layout>
        <PageTitle title1={`Admissions`} />
        <AdmissionBody />
      </Layout>
    </>
  );
}

export default Admissions;